import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(
      updateAuth({
        isAuth: false,
        userId: null,
        jwtToken: null,
        ipAddress: null,
      })
    );
    sessionStorage.clear();
    localStorage.clear();
  };

  return (
    <>
      {/* Sidebar */}

      <div className="d-flex align-items-center justify-content-between position-relative">
        <Link to="/" className="logosidebar">
          <div className="name">
            <center>
              <img
                src="/assets/image/metalogo.png"
                // src="/assets/image/METARAGS.png"
                alt=""
                className="w-100 h-100"
              />
            </center>
          </div>
        </Link>
      </div>

      <ul className="nav flex-column listitems">
        <li className="nav-item">
          <Link to="/dashboard" className="nav-link">
            {" "}
            <span className="span1">
              <i class="fa fa-home" aria-hidden="true"></i>
            </span>
            <span className="span2">Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <div className="dropdown">
            <button
              className="btn btnsecondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="span1">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
              </span>{" "}
              <span className="span2"> Activate</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <Link to="/Activate" className="dropdown-item">
                  Activate/Upgrade
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/History">
                  Activate/Upgrade History
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/IncomeLimit">
                  Income Limit History
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <div className="dropdown">
            <button
              className="btn btnsecondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="span1">
                <i class="fa fa-bandcamp" aria-hidden="true"></i>
              </span>{" "}
              <span className="span2">Genealogy</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <Link className="dropdown-item" to="/Tree_View">
                  Matching Tree
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Direct_User_List">
                  Direct User List
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Level_Details">
                  Level Details
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Downline">
                  Downline
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Direct_Leg_Business">
                  Direct Business
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/MatchingBusiness">
                  Matching Business
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/LevelTree">
                  Level Tree
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <div className="dropdown">
            <button
              className="btn btnsecondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="span1">
                <i class="fa fa-file" aria-hidden="true"></i>
              </span>{" "}
              <span className="span2">Reports</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <Link className="dropdown-item" to="/Direct_Income">
                  Direct Income
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Matching_income">
                  Daily Matching Income
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Gross_Matching_income">
                  Gross Matching Income
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Roi_Income">
                  ROI Income
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/ROI_Level_Income">
                  ROI Level Income
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Daily_all_income">
                  Daily All Income
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/RankIncome">
                  Rank Income
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <div className="dropdown">
            <button
              className="btn btnsecondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="span1">
                <i class="fa fa-university" aria-hidden="true"></i>
              </span>{" "}
              <span className="span2"> Withdrawal</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <Link className="dropdown-item" to="/Withdrawal">
                  Withdrawal
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Withdrawal_History">
                  Withdrawal History
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <div className="dropdown">
            <button
              className="btn btnsecondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="span1">
                <i class="fa fa-gamepad" aria-hidden="true"></i>
              </span>{" "}
              <span className="span2">Game Amount</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <Link className="dropdown-item" to="/GameAmountTransfer">
                  P2P Transfer
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/GameAmountTransferHistory">
                  Game Amount Transfer History
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/GameAmountReceivedHistory">
                  Game Amount Received History
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="">
          <div className="sidebaricon">
            <img
              src="/assets/image/sidbariocn.png"
              alt=""
              className="w-100 h-100"
            />
          </div>
        </li>
        <li className="">
          <Link className="btn btnsidebar" to="/Activate">
            Buy Now
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Helpdesk" className="nav-link">
            <span className="span1">
              <i class="fa fa-comments-o" aria-hidden="true"></i>
            </span>{" "}
            <span className="span2">Helpdesk</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="" onClick={handleLogout}>
            <span className="span1">
              <i class="fa fa-sign-out" aria-hidden="true"></i>
            </span>{" "}
            <span className="span2"> Sign Out</span>
          </Link>
        </li>
      </ul>
    </>
  );
}
