import React, { useEffect, useState } from "react";
import Sidebar from "../DashboardMain/Sidebar";
import "./LevelTree.css";
import UserIcon from "./user_icon.png";
import { Tooltip } from "antd";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LevelTree = () => {
  const [firstTree, setfirstTree] = useState("");
  const [secondtree, setsecondtree] = useState([]);

  // console.log("firstTree", firstTree);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useSelector((state) => state.Auth.userId);
  const jwt_token = useSelector((state) => state.Auth.jwtToken);

  const [thirdTreeId, setthirdTreeId] = useState([]);
  const [thirdtree, setthirdtree] = useState({});

  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleSecondTreeClick = (id) => {
    // console.log("id", id);
    setthirdTreeId([...thirdTreeId, id]);
    getTreeData3(id);
    setfirstTree(id);
  };

  const getTreeData1 = async () => {
    try {
      let res = await API.post(
        `/LevelTree`,
        {
          uid: userId,
          type: 2,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("ResTreee", res.data.data[0][0]);
      setfirstTree(res.data.data[0][0]);
    } catch (e) {
      if (
        e.response?.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(updateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const getTreeData2 = async () => {
    try {
      let res = await API.post(
        `/LevelTree`,
        {
          uid: userId,
          type: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("ResTreee2", res.data.data[0]);
      setsecondtree(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(updateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const getTreeData3 = async (item) => {
    try {
      if (item.downlinecount <= 0) {
        return;
      }
      let res = await API.post(
        "/LevelTree",
        {
          uid: item.uid,
          type: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("ResTreee3", res.data.data[0]);
      // setthirdtree({...thirdTreeId],[Number(item.lvluid)]:res.data.data[0]});

      // setthirdtree((prevThirdtree) => ({
      //   ...prevThirdtree,
      //   [item.lvluid]: [...res.data.data[0]],
      // }));

      setsecondtree(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTreeData1();
    getTreeData2();
  }, []);

  return (
    <div>
      <div className="container-fluid NewDashoard px-0">
        <div className="row">
          <div className="col-auto p-0">
            <div
              className={`sidebar bg-dark text-white ${
                isSidebarCollapsed ? "collapsed" : ""
              }`}
            >
              <Sidebar />
            </div>
          </div>
          <div className="col">
            <div className="flex-grow-1 marginl">
              <header className="header text-white p-3 d-flex justify-content-end align-items-center">
                {/* <div className="fw-bold fs-4 headingdah">METARAGS</div> */}
                <div className="Groupitems">
                  <div className="helpbtn">
                    <i class="fa fa-folder-open" aria-hidden="true"></i>
                  </div>
                  <a href="/Helpdesk" className="mailbtn">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  <div className="dropdown">
                    <a
                      className="btn btnsecondary dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="iconimage">
                        <img
                          className="w-100 h-10"
                          src="/assets/image/logo/logo.svg"
                          alt=""
                        />
                      </div>
                      User id:- <span>{userId}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-light togglebtn"
                  onClick={toggleSidebar}
                >
                  ☰
                </button>
              </header>
              <div
                className={`content-area ${
                  isSidebarCollapsed ? "collapsed" : ""
                }`}
              >
                <div className="row mx-0 h-100">
                  <div class="col-12">
                    <div className="d-flex align-items-center justify-content-between h-100">
                      <div className="fs-18 text-white fw-bold">Level Tree</div>
                      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Genealogy</div>
                        <div className="ps-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                              <li className="breadcrumb-item">
                                <a href="javascript:;">
                                  <i className="bx bx-home-alt text-white" />
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                Level Tree
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
                    <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
                      <div className="flexbox border-0 bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
                        <div className="flex-md-50">
                          <span className="disp-block color-grad1 text-big font-thik mb-3 fw-bold text-white">
                            Team Network : {firstTree.totaldownline}
                          </span>
                          {/* <span className="disp-block color-gray">
                            Team Network
                          </span> */}
                        </div>
                        <div className="flex-md-50 text-right mb-3 fw-bold text-white">
                          <span
                            className={`disp-block ${
                              firstTree.status === "Active"
                                ? "color-green"
                                : "color-red"
                            } text-big font-thik`}
                          >
                            Team Activation Status : {firstTree.status}
                          </span>
                          {/* <span className="disp-block color-gray">
                            Team Activation Status
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 border-0 shadow-none bdr-rad-md">
                      <div className="flexbox">
                        <div className="flex-md-100 flex-xs-100">
                          {/* <div className="flexbox pad-bt-15">
                            <div className="flex-md-100">
                              <span className="text-md disp-block color-grad1">
                                Tree view
                              </span>
                            </div>
                          </div> */}

                          {/* ecopower tree start */}
                          <div className="card-body p-2">
                            <div className="side-app">
                              <div className="main_top">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                    <div className="card shadow">
                                      <div className="card-body p-0">
                                        <div className="grid-margin">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="tree_view">
                                                <ul>
                                                  <li>
                                                    <div
                                                      className="tree_view_parent"
                                                      onmouseover="bigImg(this)"
                                                      onmouseout="normalImg(this)"
                                                    >
                                                      <img
                                                        src={UserIcon}
                                                        style={{ height: 70 }}
                                                        alt=""
                                                      />{" "}
                                                      <br />
                                                      <Tooltip
                                                        title={
                                                          <>
                                                            <div>
                                                              <p className="text-white">
                                                                Name :{" "}
                                                                {
                                                                  firstTree.f_name
                                                                }
                                                              </p>
                                                              <p className="text-white">
                                                                Referral By :{" "}
                                                                {
                                                                  firstTree.sname
                                                                }
                                                              </p>
                                                              <p className="text-white">
                                                                Team :{" "}
                                                                {
                                                                  firstTree.totaldownline
                                                                }
                                                              </p>
                                                              <p className="text-white">
                                                                Self Business :{" "}
                                                                {
                                                                  firstTree.package
                                                                }
                                                              </p>
                                                              <p className="text-white">
                                                                Team Business :{" "}
                                                                {
                                                                  firstTree.business
                                                                }
                                                              </p>
                                                            </div>
                                                          </>
                                                        }
                                                      >
                                                        <button
                                                          type="button"
                                                          className={`btn ${firstTree.btnstatus} btn-sm`}
                                                          data-html="true"
                                                          data-toggle="tooltip"
                                                          data-placement="top"
                                                          title=""
                                                          data-original-title="Name: Navritu<br> Referred By: NA<br> Team Size: 92<br>Self Business: 5000<br> Team Business: 6500"
                                                        >
                                                          {firstTree?.username}
                                                        </button>
                                                      </Tooltip>
                                                      {/* <div
                                                id="txtbussiness"
                                                style={{ fontSize: 13 }}
                                              >
                                                Bussiness:6500
                                              </div> */}
                                                    </div>
                                                    <ul>
                                                      {secondtree.map(
                                                        (items, index) => {
                                                          return (
                                                            <>
                                                              <li
                                                                className="tree_element"
                                                                data-id={637975}
                                                                data-show={1}
                                                              >
                                                                <div
                                                                  className="tree_view_parent"
                                                                  data-id={
                                                                    637975
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      UserIcon
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                      height: 50,
                                                                      width:
                                                                        "auto",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleSecondTreeClick(
                                                                        items
                                                                      )
                                                                    }
                                                                  />
                                                                  <br />
                                                                  <Tooltip
                                                                    title={
                                                                      <>
                                                                        <div>
                                                                          <p className="text-white">
                                                                            Name
                                                                            :{" "}
                                                                            {
                                                                              items.f_name
                                                                            }
                                                                          </p>
                                                                          <p className="text-white">
                                                                            Referral
                                                                            By :{" "}
                                                                            {
                                                                              items.sname
                                                                            }
                                                                          </p>
                                                                          <p className="text-white">
                                                                            Team
                                                                            :{" "}
                                                                            {
                                                                              items.totaldownline
                                                                            }
                                                                          </p>
                                                                          <p className="text-white">
                                                                            Self
                                                                            Business
                                                                            :{" "}
                                                                            {
                                                                              items.package
                                                                            }
                                                                          </p>
                                                                          <p className="text-white">
                                                                            Team
                                                                            Business
                                                                            :{" "}
                                                                            {
                                                                              items.business
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                      </>
                                                                    }
                                                                  >
                                                                    <button
                                                                      type="button"
                                                                      className={`btn ${items.btnstatus} btn-sm`}
                                                                      data-html="true"
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title=""
                                                                      data-original-title="Name: Navritu<br> Referred By: NA<br> Team Size: 92<br>Self Business: 5000<br> Team Business: 6500"
                                                                      onClick={() =>
                                                                        getTreeData1()
                                                                      }
                                                                    >
                                                                      {
                                                                        items.username
                                                                      }
                                                                    </button>
                                                                  </Tooltip>
                                                                </div>
                                                              </li>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelTree;
